import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import getEmployees from "../../../getEmployees";
import { OrderContext } from "../../../context/OrderContext";
import getOrderStatuses from "../../../getOrderStatuses";
import { createOptions } from "../../../createOptions";
import {
  addRequest,
  deleteRequest,
  getClients,
  getFilaments,
  getProducts,
  updateRequest,
} from "../../../api";
import {
  CheckboxInput,
  DateInputRequired,
  DisabledInput,
  PercentInputRequired,
  SelectInput,
  SelectInputRequired,
  TextAreaInput,
} from "../../../inputs";

const OrderForm = (props) => {
  const history = useHistory();
  const goBack = () => history.goBack();
  const [activeOrder, setActiveOrder] = useContext(OrderContext);
  if (props.action === "update" && activeOrder.id == null) {
    goBack();
  }
  const [clientID, setClientID] = useState(activeOrder?.clientID);
  const [date, setDate] = useState(new Date(activeOrder?.date || new Date()));
  const [dueDate, setDueDate] = useState(
    new Date(activeOrder?.dueDate || new Date())
  );
  const [paidFor, setPaidFor] = useState(activeOrder?.paidFor);
  const [products, setProducts] = useState(
    activeOrder?.products || [
      {
        additionalCosts: 0,
        cost: 0,
        filamentError: 0,
        filamentGrams: 0,
        quantity: 1,
      },
    ]
  );
  const [secondaryUser, setSecondaryUser] = useState(
    activeOrder?.secondaryUser
  );
  const [status, setStatus] = useState(
    activeOrder?.status || "Awaiting Approval"
  );
  const [notes, setNotes] = useState(activeOrder?.notes);
  const [saleRecorded] = useState(activeOrder?.saleRecorded);
  const [user, setUser] = useState(activeOrder?.user);
  const [userPercentage, setUserPercentage] = useState(
    activeOrder.userPercentage || 100
  );
  const [productsList, setProductsList] = useState();
  const [productsMap, setProductsMap] = useState();
  const [clients, setClients] = useState();
  const [filaments, setFilaments] = useState([]);
  const totalPrice = products.reduce(
    (total, curr) => total + curr.quantity * curr.cost || 0,
    0
  );

  const addProductToOrder = () => {
    const p = [...products];
    p.push({
      additionalCosts: 0,
      cost: 0,
      filamentError: 0,
      filamentGrams: 0,
      quantity: 1,
    });
    setProducts(p);
  };

  const getCurrentOrder = () => {
    return {
      clientID,
      date,
      dueDate,
      notes,
      paidFor,
      products,
      saleRecorded,
      secondaryUser,
      status,
      user,
      userPercentage,
    };
  };

  const saveCurrentOrder = () => {
    setActiveOrder(getCurrentOrder());
  };

  const getEndpoint = () => `orders/${activeOrder.id}`;

  const getData = async () => {
    const filamentData = getFilaments();
    let productsData = getProducts();
    let clientsData = getClients();
    setFilaments(await filamentData);
    productsData = await productsData;
    const productsOptions = productsData.map((e) => (
      <option key={e.id} value={e.id}>
        {e.name}
      </option>
    ));
    setProductsList(productsOptions);
    const map = {};
    productsData.forEach((e) => (map[e.id] = e));
    setProductsMap(map);
    setProducts(
      products.map((e) => {
        e.filamentGrams = map[e.productID]?.filamentGrams || 0;
        e.additionalCosts = map[e.productID]?.additionalCosts || 0;
        return e;
      })
    );

    clientsData = await clientsData;
    const clientsOptions = clientsData.map((e) => (
      <option key={e.id} value={e.id}>
        {e.name}
      </option>
    ));
    setClients(clientsOptions);
  };

  useEffect(() => {
    getData();
  }, []);

  const profit =
    totalPrice -
    (products.reduce(
      (acc, curr) => acc + curr.filamentGrams * curr.quantity || 0,
      0
    ) +
      products.reduce(
        (acc, curr) => acc + (curr.filamentError || 0) * curr.quantity || 0,
        0
      )) *
      0.03 -
    products.reduce((acc, curr) => acc + curr.additionalCosts || 0, 0);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const currOrder = getCurrentOrder();
    const params = {
      body: currOrder,
      endpoint: props.action === "add" ? "orders" : getEndpoint(),
    };
    const request = props.action === "add" ? addRequest : updateRequest;
    await request(params);
    goBack();
  };

  const deleteOrder = async () => {
    await deleteRequest(getEndpoint());
    goBack();
  };

  const addProduct = () => {
    saveCurrentOrder();
    history.push("/admin/products/add");
  };

  const addClient = () => {
    saveCurrentOrder();
    history.push("/admin/clients/add");
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <SelectInputRequired
              label="Client"
              options={clients}
              setMethod={setClientID}
              value={clientID}
            />
            <Button onClick={addClient}>Add New Client</Button>
            <DateInputRequired
              label={"Date"}
              setMethod={setDate}
              value={date}
            />
            <DateInputRequired
              label={"Due Date"}
              setMethod={setDueDate}
              value={dueDate}
            />
            {products.map((orderProduct, i) => {
              const setFilamentID = (e) => {
                const p = [...products];
                p[i].filamentID = e;
                setProducts(p);
              };

              return (
                <Form.Group as={Row} key={i}>
                  <Col>
                    <Form.Group
                      controlId={"productID" + i}
                      key={"productID" + i}
                    >
                      <Form.Label>Product:</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          const p = [...products];
                          p[i].productID = e.target.value;
                          p[i].cost = productsMap[e.target.value].price;
                          p[i].filamentGrams =
                            productsMap[e.target.value].filamentGrams;
                          p[i].additionalCosts =
                            productsMap[e.target.value].additionalCosts;
                          setProducts(p);
                        }}
                        required
                        type="text"
                        value={orderProduct.productID}
                      >
                        <option key=""></option>
                        {productsList}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId={"quantity" + i} key={"quantity" + i}>
                      <Form.Label>Quantity:</Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          const p = [...products];
                          p[i].quantity = parseInt(e.target.value);
                          setProducts(p);
                        }}
                        required
                        type="number"
                        value={orderProduct.quantity}
                        min="1"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId={"cost" + i} key={"cost" + i}>
                      <Form.Label>Price/Item:</Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          const p = [...products];
                          p[i].cost = parseFloat(e.target.value);
                          setProducts(p);
                        }}
                        required
                        step="0.01"
                        type="number"
                        value={orderProduct.cost}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      controlId={"filamentError" + i}
                      key={"filamentError" + i}
                    >
                      <Form.Label>Additional Filament Used:</Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          const p = [...products];
                          p[i].filamentError = parseFloat(e.target.value);
                          setProducts(p);
                        }}
                        step="0.01"
                        type="number"
                        value={orderProduct.filamentError}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <SelectInputRequired
                      label="Filament Type"
                      setMethod={setFilamentID}
                      value={orderProduct.filamentID}
                      options={createOptions(filaments, "description", "id")}
                    />
                  </Col>
                  <Col>
                    {i ? (
                      <Button
                        variant="danger"
                        onClick={() => {
                          const p = [...products];
                          p.splice(i, 1);
                          setProducts(p);
                        }}
                      >
                        Delete
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
              );
            })}
            <Button onClick={addProductToOrder}>Add Another Product</Button>
            <Button onClick={addProduct}>Add New Product</Button>
            <br />
            <Form.Label>Total Price: ${totalPrice.toFixed(2)}</Form.Label>
            <br />
            <Form.Label>Total Profit: ${profit.toFixed(2)}</Form.Label>
            <TextAreaInput label="Notes" setMethod={setNotes} value={notes} />
            <Form.Group as={Row}>
              <Col>
                <SelectInputRequired
                  label="Employee"
                  options={getEmployees}
                  setMethod={setUser}
                  value={user}
                />
              </Col>
              <Col>
                <PercentInputRequired
                  label="Percentage of Profit"
                  setMethod={setUserPercentage}
                  value={userPercentage}
                />
              </Col>
              <Col>
                <DisabledInput
                  label="Personal Profit"
                  value={((profit || 0) * 0.5 * (userPercentage || 0)) / 100}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col>
                <SelectInput
                  label="Secondary Employee"
                  setMethod={setSecondaryUser}
                  value={secondaryUser}
                  options={getEmployees}
                />
              </Col>
              <Col>
                <DisabledInput
                  label="Percentage of Profit"
                  value={100 - (userPercentage || 0)}
                />
              </Col>
              <Col>
                <DisabledInput
                  label="Personal Profit"
                  value={
                    ((profit || 0) * 0.5 * (100 - userPercentage || 0)) / 100
                  }
                />
              </Col>
            </Form.Group>
            <SelectInputRequired
              label="Status"
              setMethod={setStatus}
              value={status}
              options={getOrderStatuses()}
            />
            <CheckboxInput
              checked={paidFor}
              label="Received Payment"
              setMethod={setPaidFor}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit">
              {props.action === "add" ? "Add" : "Update"} Order
            </Button>
          </Col>
          <Col>
            <Button onClick={goBack} variant="secondary">
              Cancel
            </Button>
          </Col>
          {props.action === "update" ? (
            <Col>
              <Button onClick={deleteOrder} variant="danger">
                Delete Order
              </Button>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Form>
    </Container>
  );
};

export default OrderForm;
