import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import GetEquitys from "./GetEquitys";
import EquityHistory from "./EquityHistory";
import WithdrawCommission from "./WithdrawCommission";

const Equitys = () => {
  const match = useRouteMatch();
  const currPath = match.path;

  return (
    <>
      <Switch>
        <Route exact path={`${currPath}/get`} component={GetEquitys} />
        <Route exact path={`${currPath}/history`} component={EquityHistory} />
	  <Route exact path={`${currPath}/withdraw`} component={WithdrawCommission} />
      </Switch>
    </>
  );
};

export default Equitys;
