import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ClientContext } from "../../../context/ClientContext";
import { IDContext } from "../../../context/IDContext";
import { getClients } from "../../../api";
import TablePage from "../../../TablePage";

const GetClients = () => {
  const history = useHistory();
  const goTo = (path) => history.push(path);

  const [, setID] = useContext(IDContext);

  const [clients, setClients] = useState([]);
  const [, setActiveClient] = useContext(ClientContext);

  const getData = async () => {
    const data = await getClients();
    setClients(data);
  };

  useEffect(() => {
    setActiveClient({});
    getData();
  }, [setActiveClient]);

  const onClientClick = (client) => {
    setID(client.id);
    setActiveClient((activeClient) => ({
      ...client,
    }));
    goTo("/admin/clients/update");
  };

  const onAddClientClick = () => goTo("/admin/clients/add");

  const backToDashboard = () => goTo("/admin");

  const buttons = [
    { onClick: onAddClientClick, text: "Add Client" },
    { onClick: backToDashboard, text: "Back to Dashboard" },
  ];

  const tableHeaders = ["Name"];

  const getCells = (client) => [client.name];

  const props = {
    buttons,
    getCells,
    onRowClick: onClientClick,
    tableHeaders,
    tableRows: clients,
  };

  return <TablePage {...props} />;
};

export default GetClients;
