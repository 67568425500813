import React from "react";
import { Container, Navbar as ReactNavbar, Nav } from "react-bootstrap";
import {
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import NavbarPageLink from "./NavbarPageLink";
import NavbarIconLink from "./NavbarIconLink";
import Logo from "../assets/logo.svg";

const Navbar = () => (
  <ReactNavbar bg={"light"} expand={"md"} sticky="top">
    <Container fluid>
      <ReactNavbar.Brand href={"/"}>
        <img src={Logo} height="50" alt="React Bootstrap logo" />
      </ReactNavbar.Brand>
      <ReactNavbar.Toggle aria-controls={"basic-navbar-nav"} />
      <ReactNavbar.Collapse id={"basic-navbar-nav"}>
        <Nav className={"mr-auto"}>
          <NavbarPageLink path={"/"} text={"Home"} />
          <NavbarPageLink path={"/services"} text={"Services"} />
	  <NavbarPageLink path={"/amapps"} text={"AM Applications"} />
          <NavbarPageLink path={"/materials"} text={"Materials"} />
	  <NavbarPageLink path={"/faq"} text={"FAQ"} />
	  <NavbarPageLink path={"/about"} text={"About"} />
          <NavbarPageLink path={"/submit"} text={"Request a Quote"} />
          <NavbarIconLink
            icon={faFacebookSquare}
            link={"https://www.facebook.com/3dWinnipeg/"}
          />
        </Nav>
      </ReactNavbar.Collapse>
    </Container>
  </ReactNavbar>
);

export default Navbar;
