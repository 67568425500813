import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AccountingReport from "./AccountingReport";
import SalesBreakdownReport from "./SalesBreakdownReport";
import MaterialUsageReport from "./MaterialUsageReport";
import ReportsMain from "./ReportsMain";

const Reports = () => {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/main`} component={ReportsMain} />
        <Route
          exact
          path={`${match.path}/accounting`}
          component={AccountingReport}
        />
        <Route
          exact
          path={`${match.path}/breakdown`}
          component={SalesBreakdownReport}
        />
        <Route
          exact
          path={`${match.path}/material`}
          component={MaterialUsageReport}
        />
      </Switch>
    </>
  );
};

export default Reports;
