import React, { useState } from "react";

const ClientContext = React.createContext([{}, () => { }]);

const ClientProvider = (props) => {
  const [state, setState] = useState({});
  return (
    <ClientContext.Provider value={[state, setState]}>
      {props.children}
    </ClientContext.Provider>
  );
};

export { ClientContext, ClientProvider };
