import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { getEquityHistory } from "../../../api";
import TablePage from "../../../TablePage";

const EquityHistory = (props) => {
  const history = useHistory();

  const backToDashboard = () => history.push("/admin");
  const viewEquitys = () => history.push("/admin/equitys/get");

  const [equityHistory, setEquityHistory] = useState([]);

  const getData = async () => {
    const data = await getEquityHistory();
    setEquityHistory(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const buttons = [
    { onClick: viewEquitys, text: "View Equitys" },
    { onClick: backToDashboard, text: "Back to Dashboard" },
  ];

  const tableHeaders = ["User", "Date", "Memo", "Amount"];

  const getCells = (equity) => [
    equity.user,
    moment(equity.date).format("MMMM D, YYYY"),
    equity.memo,
    equity.amount.toFixed(2),
  ];

  const tableProps = {
    buttons,
    getCells,
    onRowClick: () => {},
    tableHeaders,
    tableRows: equityHistory,
  };

  return <TablePage {...tableProps} />;
};

export default EquityHistory;
