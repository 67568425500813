import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ActionContext } from "./context/ActionContext";
import { HistoryContext } from "./context/HistoryContext";
import { TypeContext } from "./context/TypeContext";

const FormInit = (action, type) => {
  const [, setAction] = useContext(ActionContext);
  useEffect(() => setAction(action), [action, setAction]);

  const history = useHistory();
  const [, setHistory] = useContext(HistoryContext);
  useEffect(() => setHistory(history), [history, setHistory]);

  const [, setType] = useContext(TypeContext);
  useEffect(() => setType(type), [type, setType]);
};

export default FormInit;
