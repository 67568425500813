import React, { useState } from "react";
import styled from "styled-components";
import SectionHeader from "../components/SectionHeader";
import FormGroup from "../components/FormGroup";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";

const Centered = styled.div`
  text-align: center;
`;

const Submit = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("Shipping");
  const [address, setAddress] = useState("");
  const [material, setMaterial] = useState("PLA");
  const [colour, setColour] = useState("Black");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState("");
  const [success, setSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [sending, setSending] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("deliveryMethod", deliveryMethod);
    formData.append("address", address);
    formData.append("material", material);
    formData.append("colour", colour);
    formData.append("message", message);
    for (let i = 0; i < files.length; i++) {
      formData.append("image" + (i + 1), files[i]);
    }
    setSending(true);
    await fetch("email/handler.php", {
      method: "POST",
      body: formData,
    })
      .then(() => {
        setShowFailure(false);
        setSuccess(true);
      })
      .catch(() => {
        setShowFailure(true);
      })
      .then(() => {
        setSending(false);
      });
  };

  const isShipping = () => deliveryMethod === "Shipping";
  return (
    <Container>
      <Modal show={sending} centered size="lg">
        <Modal.Body>
          <Centered>
            <h3>Uploading files and sending message, please wait...</h3>
          </Centered>
        </Modal.Body>
      </Modal>
      <Modal show={showDetails} centered size="lg">
        <Modal.Header>
          <Modal.Title>File Submission Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Attach your design with a .stl file extension and select the materal
          and color you wish to have it printed in.
          <br />
          <br />
          We will then load the design into our software and decide how it
          should be printed. We will then get back to you within <b>
            24 hours
          </b>{" "}
          with a quoted price and payment option to manufacture the attached
          design.
          <br />
          <br />
          If you do not have a design file, you can still request a quote with a
          description of what you're looking for.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDetails(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col md={12}>
          <Centered>
            <SectionHeader title={"Request a Quote!"} />
            <br />
            <a href="#" onClick={() => setShowDetails(true)}>
              <h4>View File Submission Details</h4>
            </a>
          </Centered>
        </Col>
        <Col md={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 2 }}>
          <br />
          <div className="bg-light p-4 rounded-3">
            {!success && (
              <Form onSubmit={handleSubmit}>
                <FormGroup controlId="name">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup controlId="email">
                  <Form.Label>Email:</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
		<FormGroup controlId="phone">
		  <Form.Label>Phone:</Form.Label>
		  <Form.Control
		    required
		    type="tel"
		    onChange={(e) => setPhone(e.target.value)}
		  />
		</FormGroup>
                <FormGroup controlId="delivery">
                  <Form.Label>Delivery Method:</Form.Label>
                  <Form.Check
                    type="radio"
                    id="shipping"
                    label="Shipping"
                    name="delivery"
                    checked={isShipping()}
                    onChange={() => setDeliveryMethod("Shipping")}
                  />
                  <Form.Check
                    type="radio"
                    id="delivery"
                    label="Local Pickup"
                    name="delivery"
                    checked={!isShipping()}
                    onChange={() => setDeliveryMethod("Local Pickup")}
                  />
                </FormGroup>
                {isShipping() && (
                  <FormGroup controlId="address">
                    <Form.Label>Address:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </FormGroup>
                )}
                <FormGroup controlId="material">
                  <Form.Label>
                    Material: <a href="/materials" target="_blank" rel="noopener noreferrer">More material info (new tab)</a>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => setMaterial(e.target.value)}
                  >
                    <option>PLA</option>
                    <option>PETG</option>
                    <option>TPU</option>
                    <option>Nylon</option>
                  </Form.Control>
                </FormGroup>
                <FormGroup controlId="colour">
                  <Form.Label>
                    Colour:
                  </Form.Label>
                  <Form.Control as="select" onChange={(e) => setColour(e.target.value)}>
                    <option>Black</option>
                    {(material === "PLA") && (
                      <>
                        <option>White</option>
                        <option>Red</option>
                        <option>Blue</option>
                        <option>Purple</option>
                        <option>Yellow</option>
                        <option>Orange</option>
                        <option>Silver</option>
                        <option>Grey</option>
                        <option>Wood</option>
                      </>
                    )}
                    {(material === "PETG") && (
                      <>
                        <option>White</option>
                        <option>Red</option>
                        <option>Blue</option>
                        <option>Green</option>
                      </>
                    )}
                    <option>Other (Request colour in message)</option>
                  </Form.Control>
                </FormGroup>
                <FormGroup controlId="message">
                  <Form.Label>
                    Message (Description & Application of item to be printed):
                  </Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows="7"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </FormGroup>
                <FormGroup controlId="files">
                  <Form.Label>File Upload (ctrl + click for multiple file selection):</Form.Label>
                  <Form.Control
                    type="file"
                    multiple
                    onChange={(e) => setFiles(e.target.files)}
                  />
                </FormGroup>
                <br />
                <Centered>
                  <Button type="submit">Send Request!</Button>
                </Centered>
              </Form>
            )}
            {success && (
              <Centered>
                <h2>Request Sent Successfully!</h2>
              </Centered>
            )}
            {showFailure && (
              <Centered>
                <br />
                <h4>
                  Oops, request submission failed, please contact us directly as{" "}
                  <a
                    href="mailto:inquiry@3dwinnipeg.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    inquiry@3dwinnipeg.com
                  </a>
                </h4>
              </Centered>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Submit;
