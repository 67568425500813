import dateSorter from "./dateSorter/dateSorter";

const addMethod = (method) => ({
  method,
  headers: { authorization: "Bearer " + getCookie("test123") },
});

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const addRequest = async (c) => {
  c.options = addMethod("POST");
  await requestWithBody(c);
};

export const getRequest = async (endpoint) => {
  const c = { endpoint };
  c.options = addMethod("GET");
  return await request(c);
};

export const updateRequest = async (c) => {
  c.options = addMethod("PUT");
  await requestWithBody(c);
};

export const deleteRequest = async (endpoint) => {
  const c = { endpoint };
  c.options = addMethod("DELETE");
  await request(c);
};

const patchRequest = async (endpoint) => {
  const c = { endpoint };
  c.options = addMethod("PATCH");
  await request(c);
};

const requestWithBody = async (c) => {
  c.options.headers["Content-Type"] = "application/json";
  c.options.body = JSON.stringify(c.body);
  await request(c);
};

const request = async (c) =>
  await fetch(
    (process.env.REACT_APP_API_URL || "http://localhost:3001/") + c.endpoint,
    c.options
  )
    .then((response) =>
      c.options.method === "GET" ? response.json() : response
    )
    .catch((error) => console.error("Error:", error));

export const getClients = async () => {
  const clients = await getRequest("clients");
  return clients.sort(nameSorter);
};

export const getEquitys = async () => {
  const equitys = await getRequest("equitys");
  return equitys.sort((a, b) => a.user - b.user);
};

export const getEquityHistory = async () => {
  const equityHistory = await getRequest("equitys/history");
  return dateSorter(equityHistory);
};

export const getExpenses = async () => {
  const expenses = await getRequest("expenses");
  return dateSorter(expenses);
};

export const getFilaments = async () => {
  const filaments = await getRequest("filaments");
  return filaments.sort(nameSorter);
};

export const getFilamentHistory = async () => {
  const filamentHistory = await getRequest("filaments/history");
  return dateSorter(filamentHistory);
};

export const getSales = async () => {
  const sales = await getRequest("sales");
  return dateSorter(sales);
};

export const getProducts = async () => {
  const products = await getRequest("products");
  return products.sort(nameSorter);
};

export const getOrders = async () => {
  const orders = await getRequest("orders");
  return dateSorter(orders);
};

export const filamentUsed = async (filamentID, filamentAmount) =>
  await patchRequest(`filaments/${filamentID}/used/${filamentAmount}`);

export const withdrawCommission = async (commissionID, amount) =>
  await patchRequest(`equitys/${commissionID}/withdraw/${amount}`);

export const getProductsMap = async () => await getMap("products");

export const getClientsMap = async () => await getMap("clients");

const getMap = async (endpoint) => {
  const map = {};
  const entries = await getRequest(endpoint);
  entries.forEach((entry) => (map[entry.id] = entry));
  return map;
};

const nameSorter = (prev, curr) => prev.name - curr.name;
