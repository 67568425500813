import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { withdrawCommission } from "../../../api";
import { FloatInputRequired, SelectInputRequired } from "../../../inputs";
import getEmployees from "../../../getEmployees";
import { Form, Container, Row, Col, Button } from "react-bootstrap";

const WithdrawCommission = () => {
	const history = useHistory();

	const [amount, setAmount] = useState();
	const [user, setUser] = useState();

return (
	    <Container>
	      <Form onSubmit={async (e) => {
		      e.preventDefault();
		      await withdrawCommission(user, amount);
		history.goBack();
	      }
	      }>
	        <Row>
	          <Col>
			<SelectInputRequired
	      label="Employee"
	      options={getEmployees}
	      setMethod={setUser}
	      value={user}
	    />
			<FloatInputRequired label="Amount" setMethod={setAmount} value={amount} />		
		</Col>
	        </Row>
	<Row>
	<Col>
	<Button type="submit">Withdraw</Button>
	</Col>
	<Col>
		<Button variant="secondary" onClick={history.goBack}>
	Cancel
	</Button>
	</Col>
	</Row>
	</Form>
	    </Container>
	  );
};

export default WithdrawCommission;
