import React, { useState } from "react";

const ProductContext = React.createContext([{}, () => { }]);

const ProductProvider = (props) => {
  const [state, setState] = useState({});
  return (
    <ProductContext.Provider value={[state, setState]}>
      {props.children}
    </ProductContext.Provider>
  );
};

export { ProductContext, ProductProvider };
