import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { OrderContext } from "../../context/OrderContext";
import { getClientsMap, getOrders, getProductsMap } from "../../api";
import { TablePiece } from "../../TablePage";

const Dashboard = () => {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [, setActiveOrder] = useContext(OrderContext);
  const [clientMap, setClientMap] = useState();
  const [productsMap, setProductsMap] = useState();

  const getData = async () => {
    const orders = getOrders();
    const clientsMap = getClientsMap();
    const productsMap = getProductsMap();
    setOrders(await orders);
    setClientMap(await clientsMap);
    setProductsMap(await productsMap);
  };

  useEffect(() => {
    setActiveOrder({});
    getData();
  }, [setActiveOrder]);

  const onOrderClick = (order) => {
    setActiveOrder((activeOrder) => ({
      ...order,
    }));
    history.push("/admin/orders/update");
  };

  const onAddOrderClick = () => {
    history.push("/admin/orders/add");
  };

  const manageProducts = () => history.push("/admin/products/get");
  const manageClients = () => history.push("/admin/clients/get");
  const manageExpenses = () => history.push("/admin/expenses/get");
  const manageFilaments = () => history.push("/admin/filaments/get");
  const manageSales = () => history.push("/admin/sales/get");
  const manageOrders = () => history.push("/admin/orders/get");
  const manageEquitys = () => history.push("/admin/equitys/get");
  const viewReports = () => history.push("/admin/reports/main");

  const buttons = [
    { onClick: manageProducts, text: "Manage Products" },
    { onClick: manageClients, text: "Manage Clients" },
    { onClick: manageExpenses, text: "Manage Expenses" },
    { onClick: manageFilaments, text: "Manage Filaments" },
    { onClick: manageSales, text: "Manage Sales" },
    { onClick: manageOrders, text: "Manage Orders" },
    { onClick: manageEquitys, text: "View Equities/Commissions" },
    { onClick: viewReports, text: "View Reports" },
  ];

  const nextTableHeaders = [
    "Client",
    "Product",
    "Quantity",
    "Due Date",
    "Print Hours",
    "Filament (g)",
  ];

  const nextGetCells = (order) => [
    order.client,
    order.product,
    order.quantity,
    order.dueDate,
    order.printHours,
    order.filamentGrams,
  ];

  const ordersToPrint = () => {
    const values = [];
    orders
      .filter(
        (e) =>
          e.status === "Ready to Print" || e.status === "Printing In-Progress"
      )
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
      .forEach((order) => {
        order.products.forEach((productEntry) => {
          const product = productsMap && productsMap[productEntry.productID];
          const quantity = productEntry.quantity;
          values.push({
            client: clientMap ? clientMap[order.clientID].name : "",
            product: productsMap ? product.name : "",
            quantity,
            printHours: productsMap ? product.printHours * quantity : "",
            filamentGrams: productsMap ? product.filamentGrams * quantity : "",
            ...order,
            dueDate: moment(order.dueDate).format("MMMM D, YYYY"),
          });
        });
      });
    return values;
  };

  const nextProps = {
    buttons: [],
    getCells: nextGetCells,
    onRowClick: onOrderClick,
    tableHeaders: nextTableHeaders,
    tableRows: ordersToPrint(),
  };

  const inProgressButtons = [{ onClick: onAddOrderClick, text: "Add Order" }];

  const inProgressGetCells = (order) => [
    clientMap ? clientMap[order.clientID].name : "",
    moment(order.date).format("MMMM D, YYYY"),
    order.user,
    order.status,
    order.paidFor ? "\u2713" : "",
  ];

  const inProgressTableHeaders = [
    "Client",
    "Date",
    "Employee",
    "Status",
    "Paid For",
  ];

  const inProgressOrders = orders.filter((e) => {
    return e.status !== "Delivered/Shipped" || !e.paidFor;
  });

  const inProgressProps = {
    buttons: inProgressButtons,
    getCells: inProgressGetCells,
    onRowClick: onOrderClick,
    tableHeaders: inProgressTableHeaders,
    tableRows: inProgressOrders,
  };

  return (
    <Container>
      <Row>{dashboardButtons(buttons)}</Row>
      <Section text="What to Print Next" table={nextProps} />
      <Section text="Orders In Progress" table={inProgressProps} />
    </Container>
  );
};

const Section = (props) => (
  <div className="bg-light p-5 rounded-lg">
    <Row>
      <Col>
        <h1>{props.text}</h1>
      </Col>
    </Row>
    <TablePiece {...props.table} />
  </div>
);

const DashboardButton = (props) => (
  <Col>
    <Button onClick={props.onClick}>{props.text}</Button>
  </Col>
);

const dashboardButtons = (buttons) =>
  buttons.map((props) => <DashboardButton key={props.text} {...props} />);

export default Dashboard;
