import React from "react";
import { BrowserRouter, Route, Switch, useRouteMatch } from "react-router-dom";
import Clients from "./clients/Clients";
import Dashboard from "./Dashboard";
import Equitys from "./equitys/Equitys";
import Expenses from "./expenses/Expenses";
import Filaments from "./filaments/Filaments";
import Login from "./Login";
import Orders from "./orders/Orders";
import Products from "./products/Products";
import Reports from "./reports/Reports";
import Sales from "./sales/Sales";
import { APIProvider } from "../../context/APIContext";
import { ActionProvider } from "../../context/ActionContext";
import { EquityProvider } from "../../context/EquityContext";
import { FieldsProvider } from "../../context/FieldsContext";
import { HistoryProvider } from "../../context/HistoryContext";
import { IDProvider } from "../../context/IDContext";
import { OrderProvider } from "../../context/OrderContext";
import { TypeProvider } from "../../context/TypeContext";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const match = useRouteMatch();

  return (
    <>
      <HistoryProvider>
        <TypeProvider>
          <ActionProvider>
            <OrderProvider>
              <EquityProvider>
                <FieldsProvider>
                  <IDProvider>
                    <APIProvider>
                      <Login>
                        <BrowserRouter>
                          <Switch>
                            <Route
                              exact
                              path={`${match.path}`}
                              component={Dashboard}
                            />
                            <Route
                              exact
                              path={`${match.path}/login`}
                              component={Login}
                            />
                            <Route
                              path={`${match.path}/clients`}
                              component={Clients}
                            />
                            <Route
                              path={`${match.path}/expenses`}
                              component={Expenses}
                            />
                            <Route
                              path={`${match.path}/filaments`}
                              component={Filaments}
                            />
                            <Route
                              path={`${match.path}/orders`}
                              component={Orders}
                            />
                            <Route
                              path={`${match.path}/products`}
                              component={Products}
                            />
                            <Route
                              path={`${match.path}/sales`}
                              component={Sales}
                            />
                            <Route
                              path={`${match.path}/reports`}
                              component={Reports}
                            />
                            <Route
                              path={`${match.path}/equitys`}
                              component={Equitys}
                            />
                          </Switch>
                        </BrowserRouter>
                      </Login>
                    </APIProvider>
                  </IDProvider>
                </FieldsProvider>
              </EquityProvider>
            </OrderProvider>
          </ActionProvider>
        </TypeProvider>
      </HistoryProvider>
    </>
  );
};

export default App;
