import React from "react";

const GetOrderStatuses = () =>
  [
    "Awaiting Approval",
    "Designing",
    "Ready to Print",
    "Printing In-Progress",
    "Printing Completed",
    "Delivered/Shipped",
  ].map((e) => <option key={e}>{e}</option>);

export default GetOrderStatuses;
