import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const ReportsMain = () => {
  const history = useHistory();

  const viewAccountingReport = () => history.push("/admin/reports/accounting");
  const viewSalesBreakdownReport = () =>
    history.push("/admin/reports/breakdown");
  const viewMaterialUsageReport = () => history.push("/admin/reports/material");

  const buttons = [
    { onClick: viewAccountingReport, text: "View Accounting Report" },
    { onClick: viewSalesBreakdownReport, text: "View Sales Breakdown Report" },
    { onClick: viewMaterialUsageReport, text: "View Material Usage Report" },
  ];

  const listButtons = (buttons) =>
    buttons.map((props) => <ReportButton key={props.text} {...props} />);

  const ReportButton = (props) => (
    <Col>
      <Button onClick={props.onClick}>{props.text}</Button>
    </Col>
  );

  return (
    <Container>
      <Row>{listButtons(buttons)}</Row>
    </Container>
  );
};

export default ReportsMain;
