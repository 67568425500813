import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ExpenseContext } from "../../../context/ExpenseContext";
import { IDContext } from "../../../context/IDContext";
import { getExpenses } from "../../../api";
import TablePage from "../../../TablePage";

const GetExpensesUnpaid = () => {
  const history = useHistory();
  const [expenses, setExpenses] = useState([]);
  const [, setActiveExpense] = useContext(ExpenseContext);
  const [, setTotal] = useState(0);

  const [, setID] = useContext(IDContext);

  const getUnpaidExpenses = async () => {
    const expenses = await getExpenses();
    const unpaidExpenses = expenses.filter((expense) => !expense.repaid);
    setExpenses(unpaidExpenses);
    const unpaidTotal = unpaidExpenses.reduce(
      (total, expense) => total + expense.amount,
      0
    );
    setTotal(unpaidTotal);
  };

  useEffect(() => {
    setActiveExpense({});
    getUnpaidExpenses();
  }, [setActiveExpense]);

  const onExpenseClick = (expense) => {
    setID(expense.id);
    setActiveExpense((activeExpense) => ({
      ...expense,
    }));
    history.push("/admin/expenses/update");
  };

  const viewAllExpenses = () => {
    history.push("/admin/expenses/get");
  };

  const onAddExpenseClick = () => {
    history.push("/admin/expenses/add");
  };

  const backToDashboard = () => {
    history.push("/admin");
  };

  const buttons = [
    { onClick: onAddExpenseClick, text: "Add Expense" },
    { onClick: backToDashboard, text: "Back to Dashboard" },
    { onClick: viewAllExpenses, text: "View All Expenses" },
  ];

  const tableHeaders = ["Expense", "Date", "Employee", "Amount"];

  const getCells = (expense) => [
    expense.description,
    moment(expense.date).format("MMMM D, YYYY"),
    expense.user,
    expense.amount.toFixed(2),
  ];

  const props = {
    buttons,
    getCells,
    onRowClick: onExpenseClick,
    tableHeaders,
    tableRows: expenses,
  };

  return <TablePage {...props} />;
};

export default GetExpensesUnpaid;
