import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ExpenseContext } from "../../../context/ExpenseContext";
import { IDContext } from "../../../context/IDContext";
import dateSorter from "../../../dateSorter/dateSorter";
import { getRequest } from "../../../api";
import TablePage from "../../../TablePage";

const GetExpenses = () => {
  const history = useHistory();
  const goTo = (path) => history.push(path);

  const [, setID] = useContext(IDContext);

  const [expenses, setExpenses] = useState([]);
  const [, setActiveExpense] = useContext(ExpenseContext);

  const getSortedExpenses = async () => {
    const data = await getRequest("expenses");
    setExpenses(dateSorter(data));
  };

  useEffect(() => {
    setActiveExpense({});
    getSortedExpenses();
  }, [setActiveExpense]);

  const onExpenseClick = (expense) => {
    setID(expense.id);
    setActiveExpense((activeExpense) => ({
      ...expense,
    }));
    goTo("/admin/expenses/update");
  };

  const viewUnpaidExpenses = () => {
    goTo("/admin/expenses/get/unpaid");
  };

  const onAddExpenseClick = () => {
    goTo("/admin/expenses/add");
  };

  const backToDashboard = () => {
    goTo("/admin");
  };

  const buttons = [
    { onClick: onAddExpenseClick, text: "Add Expense" },
    { onClick: backToDashboard, text: "Back to Dashboard" },
    { onClick: viewUnpaidExpenses, text: "View Unpaid Expenses" },
  ];

  const tableHeaders = ["Expense", "Date", "Amount", "Repaid"];

  const getCells = (expense) => [
    expense.description,
    moment(expense.date).format("MMMM D, YYYY"),
    expense.amount.toFixed(2),
    expense.repaid ? "Yes" : "No",
  ];

  const props = {
    buttons,
    getCells,
    onRowClick: onExpenseClick,
    tableHeaders,
    tableRows: expenses,
  };

  return <TablePage {...props} />;
};

export default GetExpenses;
