import React, { useState } from "react";

const EquityContext = React.createContext([{}, () => { }]);

const EquityProvider = (props) => {
  const [state, setState] = useState({});
  return (
    <EquityContext.Provider value={[state, setState]}>
      {props.children}
    </EquityContext.Provider>
  );
};

export { EquityContext, EquityProvider };
