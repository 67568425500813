import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Chart } from "react-google-charts";
import { getSales, getExpenses } from "../../../api";

const AccountingReport = () => {
  const history = useHistory();

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  const [startDate, setStartDate] = useState(oneYearAgo);
  const [endDate, setEndDate] = useState(new Date());
  const [sales, setSales] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [chartData, setChartData] = useState([]);

  const getData = async () => {
    const sales = getSales();
    const expenses = getExpenses();
    setSales(await sales);
    setExpenses(await expenses);
  };

  const backToDashboard = () => {
    history.push("/admin");
  };

  const backToReports = () => {
    history.push("/admin/reports/main");
  };

  const isWithinDates = (entry) =>
    moment(startDate).isSameOrBefore(entry.date, "day") &&
    moment(endDate).isSameOrAfter(entry.date, "day");

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthDiff = (dateFrom, dateTo) =>
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear());

  const getChartData = () => {
    let months = new Array(monthDiff(startDate, endDate) + 1).fill(1);
    months = months.map((month, i) => {
      const monthName = monthNames[(startDate.getMonth() + i) % 12];
      const year =
        startDate.getFullYear() + Math.floor((startDate.getMonth() + i) / 12);
      const name = monthName + " " + year;
      return [name, 0, 0, 0];
    });
    const filteredSales = sales.filter(isWithinDates);
    filteredSales.forEach((sale) => {
      const index = monthDiff(startDate, sale.date);
      months[index][1] += sale.amount;
      months[index][3] += sale.amount;
    });
    const filteredExpenses = expenses.filter(isWithinDates);
    filteredExpenses.forEach((expense) => {
      const index = monthDiff(startDate, expense.date);
      months[index][2] -= expense.amount;
      months[index][3] -= expense.amount;
    });
    months.unshift(["Month", "Sales", "Expenses", "Net"]);
    setChartData(months);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getChartData();
  }, [startDate, endDate, sales, expenses]);

  return (
    <Container>
      <Row>
        <Col>
          <Button onClick={backToReports}>Other Reports</Button>
        </Col>
        <Col>
          <Button onClick={backToDashboard}>Back to Dashboard</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Start Date: </Form.Label>
            <DatePicker
              dateFormat="MMMM d, yyyy"
              onChange={(e) => setStartDate(e)}
              selected={startDate}
              type="date"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>End Date: </Form.Label>
            <DatePicker
              dateFormat="MMMM d, yyyy"
              onChange={(e) => setEndDate(e)}
              selected={endDate}
              type="date"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Chart chartType="Bar" height="500px" data={chartData} />
        </Col>
      </Row>
    </Container>
  );
};

export default AccountingReport;
