import React, { useState, useEffect, useContext } from "react";
import { ClientContext } from "../../../context/ClientContext";
import { FieldsContext } from "../../../context/FieldsContext";
import { EmailInput, EditForm, TextInputRequired } from "../../../inputs";
import FormInit from "../../../FormInit";

const ClientForm = (props) => {
  FormInit(props.action, "Client");

  const [activeClient] = useContext(ClientContext);
  const [email, setEmail] = useState(activeClient.email);
  const [name, setName] = useState(activeClient.name);

  const [, setFields] = useContext(FieldsContext);
  useEffect(() => {
    const fields = {
      email,
      name,
    };
    setFields(fields);
  }, [email, name, setFields]);

  const inputs = [
    <TextInputRequired label="Name" setMethod={setName} value={name} />,
    <EmailInput label="Email" setMethod={setEmail} value={email} />,
  ];

  return <EditForm inputs={inputs} />;
};

export default ClientForm;
