import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ProductContext } from "../../../context/ProductContext";
import { IDContext } from "../../../context/IDContext";
import TablePage from "../../../TablePage";
import { getProducts } from "../../../api";

const GetProducts = () => {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [, setActiveProduct] = useContext(ProductContext);

  const [, setID] = useContext(IDContext);

  const getData = async () => {
    const products = await getProducts();
    setProducts(products);
  };

  useEffect(() => {
    setActiveProduct({});
    getData();
  }, [setActiveProduct]);

  const onProductClick = (product) => {
    setID(product.id);
    setActiveProduct((activeProduct) => ({
      ...product,
    }));
    history.push("/admin/products/update");
  };

  const onAddProductClick = () => {
    history.push("/admin/products/add");
  };

  const backToDashboard = () => {
    history.push("/admin");
  };

  const buttons = [
    { onClick: onAddProductClick, text: "Add Product" },
    { onClick: backToDashboard, text: "Back to Dashboard" },
  ];

  const tableHeaders = ["Product", "Price"];

  const getCells = (product) => [product.name, product.price.toFixed(2)];

  const props = {
    buttons,
    getCells,
    onRowClick: onProductClick,
    tableHeaders,
    tableRows: products,
  };

  return <TablePage {...props} />;
};

export default GetProducts;
