import React, { useState, useContext, useEffect } from "react";
import { ExpenseContext } from "../../../context/ExpenseContext";
import { FieldsContext } from "../../../context/FieldsContext";
import getEmployees from "../../../getEmployees";
import {
  CheckboxInput,
  DateInputRequired,
  FloatInputRequired,
  SelectInputRequired,
  TextInputRequired,
  EditForm,
} from "../../../inputs";
import FormInit from "../../../FormInit";

const ExpenseForm = (props) => {
  FormInit(props.action, "Expense");

  const [activeExpense] = useContext(ExpenseContext);
  const [amount, setAmount] = useState(activeExpense.amount);
  const [date, setDate] = useState(new Date(activeExpense.date || new Date()));
  const [description, setDescription] = useState(activeExpense.description);
  const [repaid, setRepaid] = useState(activeExpense.repaid);
  const [user, setUser] = useState(activeExpense.user);

  const [, setFields] = useContext(FieldsContext);
  useEffect(() => {
    const fields = {
      amount,
      date,
      description,
      repaid,
      user,
    };
    setFields(fields);
  }, [amount, date, description, repaid, user, setFields]);

  const inputs = [
    <TextInputRequired
      label="Description"
      setMethod={setDescription}
      value={description}
    />,
    <DateInputRequired label="Date" setMethod={setDate} value={date} />,
    <FloatInputRequired label="Amount" setMethod={setAmount} value={amount} />,
    <SelectInputRequired
      label="Employee"
      options={getEmployees}
      setMethod={setUser}
      value={user}
    />,
    <CheckboxInput checked={repaid} label="Repaid" setMethod={setRepaid} />,
  ];

  return <EditForm inputs={inputs} />;
};

export default ExpenseForm;
