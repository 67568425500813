import React, { useState } from "react";

const SaleContext = React.createContext([{}, () => { }]);

const SaleProvider = (props) => {
  const [state, setState] = useState({});
  return (
    <SaleContext.Provider value={[state, setState]}>
      {props.children}
    </SaleContext.Provider>
  );
};

export { SaleContext, SaleProvider };
