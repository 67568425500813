import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { FilamentContext } from "../../../context/FilamentContext";
import { IDContext } from "../../../context/IDContext";
import { getFilaments } from "../../../api";
import TablePage from "../../../TablePage";

const GetFilaments = () => {
  const history = useHistory();
  const goTo = (path) => history.push(path);

  const [, setID] = useContext(IDContext);

  const [filaments, setFilaments] = useState([]);
  const [, setActiveFilament] = useContext(FilamentContext);

  const getData = async () => {
    const data = await getFilaments();
    setFilaments(data);
  };

  useEffect(() => {
    setActiveFilament({});
    getData();
  }, [setActiveFilament]);

  const onFilamentClick = (filament) => {
    setID(filament.id);
    setActiveFilament((activeFilament) => ({
      ...filament,
    }));
    goTo("/admin/filaments/update");
  };

  const onAddFilamentClick = () => goTo("/admin/filaments/add");

  const viewFilamentHistory = () => goTo("/admin/filaments/history");

  const backToDashboard = () => goTo("/admin");

  const buttons = [
    { onClick: onAddFilamentClick, text: "Add Filament" },
    { onClick: viewFilamentHistory, text: "View History" },
    { onClick: backToDashboard, text: "Back to Dashboard" },
  ];

  const tableHeaders = ["Description", "Amount Left (g)"];

  const getCells = (filament) => [filament.description, filament.amountLeft];

  const props = {
    buttons,
    getCells,
    onRowClick: onFilamentClick,
    tableHeaders,
    tableRows: filaments,
  };

  return <TablePage {...props} />;
};

export default GetFilaments;
