import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { SaleProvider } from "../../../context/SaleContext";
import SaleForm from "./SaleForm";
import GetSales from "./GetSales";

const Sales = () => {
  const match = useRouteMatch();

  return (
    <>
      <SaleProvider>
        <Switch>
          <Route exact path={`${match.path}/add`}>
            <SaleForm action="add" />
          </Route>
          <Route exact path={`${match.path}/get`} component={GetSales} />
          <Route exact path={`${match.path}/update`}>
            <SaleForm action="update" />
          </Route>
        </Switch>
      </SaleProvider>
    </>
  );
};

export default Sales;
