import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { SaleContext } from "../../../context/SaleContext";
import { IDContext } from "../../../context/IDContext";
import { getSales, getProductsMap, getClientsMap } from "../../../api";
import TablePage from "../../../TablePage";

const GetSales = () => {
  const history = useHistory();

  const [, setID] = useContext(IDContext);

  const [sales, setSales] = useState([]);
  const [, setActiveSale] = useContext(SaleContext);
  const [productMap, setProductMap] = useState();
  const [clientMap, setClientMap] = useState();

  const getData = async () => {
    const sales = getSales();
    const productsMap = getProductsMap();
    const clientsMap = getClientsMap();
    setSales(await sales);
    setProductMap(await productsMap);
    setClientMap(await clientsMap);
  };

  useEffect(() => {
    setActiveSale({});
    getData();
  }, [setActiveSale]);

  const onSaleClick = (sale) => {
    setID(sale.id);
    setActiveSale((activeSale) => ({
      ...sale,
    }));
    history.push("/admin/sales/update");
  };

  const onAddSaleClick = () => {
    history.push("/admin/sales/add");
  };

  const backToDashboard = () => {
    history.push("/admin");
  };

  const buttons = [
    { onClick: onAddSaleClick, text: "Add Sale" },
    { onClick: backToDashboard, text: "Back to Dashboard" },
  ];

  const tableHeaders = ["Product", "Client", "Date", "User", "Amount"];

  const getCells = (sale) => [
    productMap ? productMap[sale.productID].name : "",
    clientMap ? clientMap[sale.clientID].name : "",
    moment(sale.date).format("MMMM D, YYYY"),
    sale.user,
    sale.amount.toFixed(2),
  ];

  const props = {
    buttons,
    getCells,
    onRowClick: onSaleClick,
    tableHeaders,
    tableRows: sales,
  };

  return <TablePage {...props} />;
};

export default GetSales;
