import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getEquitys } from "../../../api";
import TablePage from "../../../TablePage";

const GetEquitys = () => {
  const history = useHistory();
  const goTo = (path) => history.push(path);

  const [equitys, setEquitys] = useState([]);

  const getData = async () => {
    const data = await getEquitys();
    setEquitys(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const viewEquitiesHistory = () => goTo("/admin/equitys/history");

  const backToDashboard = () => goTo("/admin");

	const withdrawCommission = () => goTo("/admin/equitys/withdraw");

  const buttons = [
    { onClick: viewEquitiesHistory, text: "View History" },
	  { onClick: withdrawCommission, text: "Withdraw Commission" },
    { onClick: backToDashboard, text: "Back to Dashboard" },
  ];

  const tableHeaders = [
    "Employee",
    "Unpaid Commissions",
    "Unpaid Expenses",
    "Equity",
    "Total",
  ];

  const getCells = (equity) => {
    const total = equity.commission + equity.expenses + equity.value;
    return [
      equity.id,
      equity.commission.toFixed(2),
      equity.expenses.toFixed(2),
      equity.value.toFixed(2),
      total.toFixed(2),
    ];
  };

  const props = {
    buttons,
    getCells,
    onRowClick: () => {},
    tableHeaders,
    tableRows: equitys,
  };

  return <TablePage {...props} />;
};

export default GetEquitys;
