import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { OrderContext } from "../../../context/OrderContext";
import TablePage from "../../../TablePage";
import { getOrders, getClientsMap } from "../../../api";

const GetOrders = () => {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [, setActiveOrder] = useContext(OrderContext);
  const [clientMap, setClientMap] = useState();

  const getData = async () => {
    const orders = getOrders();
    const clientsMap = getClientsMap();
    setOrders(await orders);
    setClientMap(await clientsMap);
  };

  useEffect(() => {
    setActiveOrder({});
    getData();
  }, [setActiveOrder]);

  const onOrderClick = (order) => {
    setActiveOrder((activeOrder) => ({
      ...order,
    }));
    history.push("/admin/orders/update");
  };

  const onAddOrderClick = () => {
    history.push("/admin/orders/add");
  };

  const backToDashboard = () => {
    history.push("/admin");
  };

  const buttons = [
    { onClick: onAddOrderClick, text: "Add Order" },
    { onClick: backToDashboard, text: "Back to Dashboard" },
  ];

  const tableHeaders = ["Client", "Date", "Employee", "Status"];

  const getCells = (order) => [
    clientMap ? clientMap[order.clientID].name : "",
    moment(order.date).format("MMMM D, YYYY"),
    order.user,
    order.status,
  ];

  const props = {
    buttons,
    getCells,
    onRowClick: onOrderClick,
    tableHeaders,
    tableRows: orders,
  };

  return <TablePage {...props} />;
};

export default GetOrders;
