import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = styled(FontAwesomeIcon)`
  margin-top: 3px;
`;

const NavbarIconLink = (props) => {
  const { icon, link } = props;
  return (
    <Nav.Link target="_blank" href={link}>
      {React.isValidElement(icon) ? (
        icon
      ) : (
        <Icon icon={icon} size="lg" color="black" />
      )}
    </Nav.Link>
  );
};

NavbarIconLink.propTypes = {
  link: PropTypes.string.isRequired,
};

export default NavbarIconLink;
