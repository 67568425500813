import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Nav } from "react-bootstrap";

const NavLink = styled(Nav.Link)`
  font-size: 18px;
`;

const NavbarPageLink = (props) => {
  const { path, text } = props;
  return <NavLink href={path}>{text}</NavLink>;
};

NavbarPageLink.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default NavbarPageLink;
