import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ProductProvider } from "../../../context/ProductContext";
import ProductForm from "./ProductForm";
import GetProducts from "./GetProducts";

const Products = () => {
  const match = useRouteMatch();

  return (
    <>
      <ProductProvider>
        <Switch>
          <Route path={`${match.path}/add`}>
            <ProductForm action="add" />
          </Route>
          <Route exact path={`${match.path}/get`} component={GetProducts} />
          <Route exact path={`${match.path}/update`}>
            <ProductForm action="update" />
          </Route>
        </Switch>
      </ProductProvider>
    </>
  );
};

export default Products;
