import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import SectionHeader from "../components/SectionHeader";

const Text = styled.p`
  font-size: 22px;
  font-weight: 500;
`;

const SecondaryHeader = styled.h2`
  color: #1b3465;
  font-weight: 700;
  margin: 35px 0;
  text-decoration: underline;
`;

const About = () => (
  <Container>
    <Row>
      <Col>
        <SectionHeader title={"Frequently Asked Questions"} />
        <SecondaryHeader>
          What is Additive Manufacturing (3D Printing)?
        </SecondaryHeader>
        <Text>
          3D printing is a way of creating solid objects by building the object
          up layer by layer, typically using filament available in various
          plastics. 3D printing is a very effective and affordable way of
          creating custom/everyday objects. 3D printing also allows for quick
          turnaround times on design production as well as provides a
          manufacturing method for more complex ideas.
        </Text>
        <SecondaryHeader>How Do I get an item Printed?</SecondaryHeader>
        <Text>
          Send us the item you want printed through our{" "}
          <a href="/submit">Request a Quote</a> page in the form of a .STL file
          or send us an email with a description of what you are looking for. We
          will reply with a quote within 24 hours of submission.
        </Text>
        <SecondaryHeader>How can I get a Custom Design?</SecondaryHeader>
        <Text>
          3D Winnipeg helps clients with their ideas by offering our 3D
          Modelling skills combined with our expertise in additive manufacturing
          to allow you to explore design options and get a hands-on feel of your
          idea. <a href="/contact">Contact us</a> today with a description of
          your design idea and we will get back to you with how our services can
          help you!
        </Text>
      </Col>
    </Row>
  </Container>
);

export default About;
