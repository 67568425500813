import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import SectionHeader from "../components/SectionHeader";
import HorizontalImageInfo from "../components/HorizontalImageInfo";
import design from "../assets/design.png";
import manufacturing from "../assets/manufacturing.png";
import consulting from "../assets/consulting.JPG";

const Description = styled.p`
  font-size: 25px;
  margin-top: 25px;
`;

const Services = () => (
  <Container>
    <Row>
      <Col>
        <SectionHeader title="What We Offer" />
      </Col>
    </Row>
    <HorizontalImageInfo
      primaryHeader="Manufacturing"
      secondaryHeader="Construct Your Design Through 3D Printing"
      src={manufacturing}
      description={
        <Description>
          Through the use of additive manufacturing, we provide companies a cost
          effective and efficient way to manufacture products that are more
          complex, lighter and with shorter lead times than traditional
          manufacturing methods.
          <br />
          <br />
          With our multitude of 3D printers, we are able to provide short lead
          times to ensure your design is produced in the shortest possible time.
          We also offer small batch manufacturing to produce a larger amount of
          product at a more affordable price than traditional manufacturing
          methods.
          <br />
          <br />
          <a href="/contact">Contact us</a> today to create your design through
          3D printing.
        </Description>
      }
    />
    <HorizontalImageInfo
      primaryHeader="Design & DfAM (Design for Additive Manufacturing)"
      secondaryHeader="Bring Your Ideas to Life"
      src={design}
      description={
        <Description>
          3D Winnipeg helps clients with their ideas by offering our 3D
          Modelling skills combined with our expertise in additive manufacturing
          to allow you to explore design options and get a hands on feel of your
          idea.
          <br />
          <br />
          We have completed design commissions that have ranged from photography
          props to 3D printed models of homes for architectural demonstration.
          <br />
          <br />
          <a href="/contact">Contact us</a> today so that we can help you bring
          your ideas to life.
        </Description>
      }
    />
    <HorizontalImageInfo
      primaryHeader="Consulting"
      secondaryHeader="Let Our Experts Help"
      src={consulting}
      description={
        <Description>
          We have a team of engineers with a vast amount of experience to help
          you discover what additive manufacturing can do for you. Get in touch
          with our team to assist you with your next project.
          <br />
          <br />
          Our team has a wide range of experience in both design and additive
          manufacturing over numerous projects. Finding out innovative design
          and manufacturing solutions can provide benefit to any company.
          <br />
          <br />
          <a href="/contact">Contact us</a> today to have our team on your side
          for your next project.
        </Description>
      }
    />
  </Container>
);

export default Services;
