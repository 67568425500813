import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ExpenseProvider } from "../../../context/ExpenseContext";
import ExpenseForm from "./ExpenseForm";
import GetExpenses from "./GetExpenses";
import GetExpensesUnpaid from "./GetExpensesUnpaid";

const Expenses = () => {
  const match = useRouteMatch();

  return (
    <>
      <ExpenseProvider>
        <Switch>
          <Route exact path={`${match.path}/add`}>
            <ExpenseForm action="add" />
          </Route>
          <Route exact path={`${match.path}/get`} component={GetExpenses} />
          <Route
            exact
            path={`${match.path}/get/unpaid`}
            component={GetExpensesUnpaid}
          />
          <Route exact path={`${match.path}/update`}>
            <ExpenseForm action="update" />
          </Route>
        </Switch>
      </ExpenseProvider>
    </>
  );
};

export default Expenses;
