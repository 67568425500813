import React, { useState } from "react";

const FilamentContext = React.createContext([{}, () => { }]);

const FilamentProvider = (props) => {
  const [state, setState] = useState({});
  return (
    <FilamentContext.Provider value={[state, setState]}>
      {props.children}
    </FilamentContext.Provider>
  );
};

export { FilamentContext, FilamentProvider };
