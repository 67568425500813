import React, { useState, useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { ProductContext } from "../../../context/ProductContext";
import { FieldsContext } from "../../../context/FieldsContext";
import {
  TextInputRequired,
  FloatInputRequired,
  SelectInputRequired,
  EditForm,
} from "../../../inputs";
import FormInit from "../../../FormInit";

const ProductForm = (props) => {
  FormInit(props.action, "Product");

  const [activeProduct] = useContext(ProductContext);
  const [category, setCategory] = useState(activeProduct.category);
  const [name, setName] = useState(activeProduct.name);
  const [printHours, setPrintHours] = useState(activeProduct.printHours);
  const [filamentGrams, setFilamentGrams] = useState(
    activeProduct.filamentGrams
  );
  const [additionalCosts, setAdditionalCosts] = useState(
    activeProduct.additionalCosts
  );
  const [price, setPrice] = useState(activeProduct.price);
  const costToMake = filamentGrams * 0.03 + additionalCosts || 0;
  const filamentCharge = filamentGrams * 0.04 || 0;
  const printingCost = 5.8 + 0.85 * printHours || 0;
  const profitPerItem = price - costToMake;

  const [, setFields] = useContext(FieldsContext);
  useEffect(() => {
    const fields = {
      additionalCosts,
      category,
      filamentGrams,
      name,
      price,
      printHours,
    };
    setFields(fields);
  }, [
    additionalCosts,
    category,
    filamentGrams,
    name,
    price,
    printHours,
    setFields,
  ]);

  const inputs = [
    <TextInputRequired label="Name" setMethod={setName} value={name} />,
    <SelectInputRequired
      label="Category"
      setMethod={setCategory}
      value={category}
      options={
        <>
          <option>Custom Design</option>
          <option>File</option>
          <option>Print By Request</option>
        </>
      }
    />,
    <FloatInputRequired
      label="Print Hours"
      setMethod={setPrintHours}
      value={printHours}
    />,
    <FloatInputRequired
      label="Filament Grams"
      setMethod={setFilamentGrams}
      value={filamentGrams}
    />,
    <FloatInputRequired
      label="Additional Costs"
      setMethod={setAdditionalCosts}
      value={additionalCosts}
    />,
    <Form.Label>Cost to make: $</Form.Label>,
    costToMake.toFixed(2),
    <br />,
    <Form.Label>Filament Charge: $</Form.Label>,
    filamentCharge.toFixed(2),
    <br />,
    <Form.Label>Printing Cost: $</Form.Label>,
    printingCost.toFixed(2),
    <FloatInputRequired label="Price" setMethod={setPrice} value={price} />,
    <Form.Label>Profit Per Item: $</Form.Label>,
    (profitPerItem || 0).toFixed(2),
  ];

  return <EditForm inputs={inputs} />;
};

export default ProductForm;
