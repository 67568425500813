import React, { useState } from "react";

const IDContext = React.createContext([{}, () => { }]);

const IDProvider = (props) => {
  const [state, setState] = useState("");
  return (
    <IDContext.Provider value={[state, setState]}>
      {props.children}
    </IDContext.Provider>
  );
};

export { IDContext, IDProvider };
