import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { getFilamentHistory } from "../../../api";
import TablePage from "../../../TablePage";

const FilamentHistory = (props) => {
  const history = useHistory();

  const backToDashboard = () => history.push("/admin");
  const viewFilaments = () => history.push("/admin/filaments/get");

  const [filamentHistory, setFilamentHistory] = useState([]);

  const getData = async () => {
    const data = await getFilamentHistory();
    setFilamentHistory(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const buttons = [
    { onClick: viewFilaments, text: "View Filaments" },
    { onClick: backToDashboard, text: "Back to Dashboard" },
  ];

  const tableHeaders = ["Filament", "Date", "Product", "Amount (g)"];

  const getCells = (filament) => [
    filament.filament,
    moment(filament.date).format("MMMM D, YYYY"),
    filament.product,
    filament.amount,
  ];

  const tableProps = {
    buttons,
    getCells,
    onRowClick: () => {},
    tableHeaders,
    tableRows: filamentHistory,
  };

  return <TablePage {...tableProps} />;
};

export default FilamentHistory;
