import React, { Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import About from "./views/About";
import AMApps from "./views/AMApps";
import FAQ from "./views/FAQ";
import Home from "./views/Home";
import Materials from "./views/Materials";
import Navbar from "./components/Navbar";
import Submit from "./views/Submit";
import Services from "./views/Services";
import Admin from "./views/admin/Admin.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const App = () => (
  <Fragment>
    <Navbar />
    <BrowserRouter>
      <Switch>
        <Route exact path={"/"} component={Home} />
        <Route exact path={"/about"} component={About} />
	<Route exact path={"/amapps"} component={AMApps} />
        <Route exact path={"/materials"} component={Materials} />
	<Route exact path={"/faq"} component={FAQ} />
        <Route exact path={"/submit"} component={Submit} />
        <Route exact path={"/services"} component={Services} />
        <Route path={"/admin"} component={Admin} />
      </Switch>
    </BrowserRouter>
  </Fragment>
);

export default App;
