import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { FilamentProvider } from "../../../context/FilamentContext";
import FilamentForm from "./FilamentForm";
import GetFilaments from "./GetFilaments";
import FilamentHistory from "./FilamentHistory";

const Filaments = () => {
  const match = useRouteMatch();

  return (
    <>
      <FilamentProvider>
        <Switch>
          <Route exact path={`${match.path}/add`}>
            <FilamentForm action="add" />
          </Route>
          <Route exact path={`${match.path}/get`} component={GetFilaments} />
          <Route
            exact
            path={`${match.path}/history`}
            component={FilamentHistory}
          />
          <Route exact path={`${match.path}/update`}>
            <FilamentForm action="update" />
          </Route>
        </Switch>
      </FilamentProvider>
    </>
  );
};

export default Filaments;
