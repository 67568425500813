import React, { useState } from "react";

const ActionContext = React.createContext([{}, () => { }]);

const ActionProvider = (props) => {
  const [state, setState] = useState({});
  return (
    <ActionContext.Provider value={[state, setState]}>
      {props.children}
    </ActionContext.Provider>
  );
};

export { ActionContext, ActionProvider };
