import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ClientProvider } from "../../../context/ClientContext";
import ClientForm from "./ClientForm";
import GetClients from "./GetClients";

const Clients = () => {
  const match = useRouteMatch();

  return (
    <>
      <ClientProvider>
        <Switch>
          <Route exact path={`${match.path}/add`}>
            <ClientForm action="add" />
          </Route>
          <Route exact path={`${match.path}/get`} component={GetClients} />
          <Route exact path={`${match.path}/update`}>
            <ClientForm action="update" />
          </Route>
        </Switch>
      </ClientProvider>
    </>
  );
};

export default Clients;
