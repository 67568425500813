import React, { useContext, useEffect, useState } from "react";
import { HistoryContext } from "./HistoryContext";
import { ActionContext } from "./ActionContext";
import { IDContext } from "./IDContext";
import { TypeContext } from "./TypeContext";
import { FieldsContext } from "./FieldsContext";
import { addRequest, updateRequest, deleteRequest } from "../api";

const APIContext = React.createContext([{}, () => { }]);

const APIProvider = (props) => {
  const [history] = useContext(HistoryContext);
  const [action] = useContext(ActionContext);
  const [id] = useContext(IDContext);
  const [type] = useContext(TypeContext);
  const [fields] = useContext(FieldsContext);

  const [state, setState] = useState({});
  useEffect(() => {
    const goBack = () => history.goBack();
    if (action === "update" && id === "") goBack();
    const path = type.toLowerCase() + "s";
    const pathWithID = path + "/" + id;
    const params = {
      onSubmit: async (event) => {
        event.preventDefault();
        const endpoint = action === "add" ? path : pathWithID;
        const requestParams = { body: fields, endpoint };
        const request = action === "add" ? addRequest : updateRequest;
        await request(requestParams);
        goBack();
      },
      delete: async () => {
        await deleteRequest(pathWithID);
        goBack();
      },
    };
    setState(params);
  }, [action, type, id, history, fields]);
  return (
    <APIContext.Provider value={[state, setState]}>
      {props.children}
    </APIContext.Provider>
  );
};

export { APIContext, APIProvider };
