import React from "react";
import styled from "styled-components";
import { Col, Image as BootstrapImage } from "react-bootstrap";

const Image = styled(BootstrapImage)`
  height: 266px;
  object-fit: cover;
  width: 100%;
`;

const PrimaryHeader = styled.h2`
  color: #1b3465;
  font-weight: 600;
  margin-top: 30px;
  text-align: center;
`;

const SecondaryHeader = styled.h3`
  font-weight: 600;
  margin-top: 25px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 28px;
  margin-top: 25px;
  text-align: center;
`;

const VerticalImageInfo = (props) => (
  <Col>
    <Image src={props.src} />
    <PrimaryHeader>{props.primaryHeader}</PrimaryHeader>
    <SecondaryHeader>{props.secondaryHeader}</SecondaryHeader>
    <Description>{props.description}</Description>
  </Col>
);

export default VerticalImageInfo;
