import React, { useState } from "react";

const OrderContext = React.createContext([{}, () => { }]);

const OrderProvider = (props) => {
  const [state, setState] = useState({});
  return (
    <OrderContext.Provider value={[state, setState]}>
      {props.children}
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderProvider };
