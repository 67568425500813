import React, { useState, useEffect, useContext } from "react";
import { FilamentContext } from "../../../context/FilamentContext";
import { FieldsContext } from "../../../context/FieldsContext";
import {
  FloatInputRequired,
  EditForm,
  TextInputRequired,
} from "../../../inputs";
import FormInit from "../../../FormInit";

const FilamentForm = (props) => {
  FormInit(props.action, "Filament");

  const [activeFilament] = useContext(FilamentContext);
  const [description, setDescription] = useState(activeFilament.description);
  const [amountLeft, setAmountLeft] = useState(activeFilament.amountLeft);
  const [remindAt, setRemindAt] = useState(activeFilament.remindAt);
  const [link, setLink] = useState(activeFilament.link);

  const [, setFields] = useContext(FieldsContext);
  useEffect(() => {
    const fields = {
      amountLeft,
      description,
      link,
      remindAt,
    };
    setFields(fields);
  }, [amountLeft, description, link, remindAt, setFields]);

  const inputs = [
    <TextInputRequired
      label="Description"
      setMethod={setDescription}
      value={description}
    />,
    <FloatInputRequired
      label="Amount Left"
      setMethod={setAmountLeft}
      value={amountLeft}
    />,
    <FloatInputRequired
      label="Remind At"
      setMethod={setRemindAt}
      value={remindAt}
    />,
    <TextInputRequired label="Reorder Link" setMethod={setLink} value={link} />,
  ];

  return <EditForm inputs={inputs} />;
};

export default FilamentForm;
