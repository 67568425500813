import React, { useState } from "react";

const TypeContext = React.createContext([{}, () => { }]);

const TypeProvider = (props) => {
  const [state, setState] = useState("");
  return (
    <TypeContext.Provider value={[state, setState]}>
      {props.children}
    </TypeContext.Provider>
  );
};

export { TypeContext, TypeProvider };
