import React, { useState } from "react";
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const Login = (props) => {
  const [isAuth, setIsAuth] = useState(false);
  const requireLogin = process.env.REACT_APP_REQUIRE_LOGIN || false;

  if (isAuth || !requireLogin) return <>{props.children}</>;

  const firebaseConfig = {
    apiKey: "AIzaSyC1PpblyrGMBIjPKo-APpvaoREnct1xgqA",
    authDomain: "d-winnipeg-tracking.firebaseapp.com",
    projectId: "d-winnipeg-tracking",
    storageBucket: "d-winnipeg-tracking.appspot.com",
    messagingSenderId: "45342753538",
    appId: "1:45342753538:web:216530ba4fee50a190781b",
    measurementId: "G-QY00EB4J3Y"
  };

  initializeApp(firebaseConfig);

  const provider = new GoogleAuthProvider();
  const auth = getAuth();

  signInWithPopup(auth, provider)
    .then((result) => {
      document.cookie = "test123=" + result.user.accessToken;
      setIsAuth(true);
    }).catch((error) => {
      console.log(error)
    });

  return (<></>);
};

export default Login;
