import React, { useState } from "react";

const HistoryContext = React.createContext([{}, () => { }]);

const HistoryProvider = (props) => {
  const [state, setState] = useState({});
  return (
    <HistoryContext.Provider value={[state, setState]}>
      {props.children}
    </HistoryContext.Provider>
  );
};

export { HistoryContext, HistoryProvider };
