import React, { useContext } from "react";
import { Button, Container, Col, Form, Row } from "react-bootstrap";
import { ActionContext } from "./context/ActionContext";
import { TypeContext } from "./context/TypeContext";
import { HistoryContext } from "./context/HistoryContext";
import { APIContext } from "./context/APIContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const getFormLabel = (text) => <Form.Label>{text}:</Form.Label>;

export const getValue = (e) => e.target.value;

export const isAddingState = (e) => e === "add";

export const EditForm = (props) => {
  const [api] = useContext(APIContext);
  const inputs = props.inputs.map((input, index) =>
    <div key={index}>
      {input}
    </div>
  );
  return (
    <Container>
      <Form onSubmit={api.onSubmit}>
        <Row>
          <Col>{inputs}</Col>
        </Row>
        <FormButtons />
      </Form>
    </Container>
  );
};

export const FormButtons = () => {
  const [action] = useContext(ActionContext);
  const [type] = useContext(TypeContext);
  const [history] = useContext(HistoryContext);
  const [api] = useContext(APIContext);
  const isAdding = action === "add";
  return (
    <Row>
      <Col>
        <Button type="submit">
          {isAdding ? "Add" : "Update"}
          {" " + type}
        </Button>
      </Col>
      <Col>
        <Button variant="secondary" onClick={history.goBack}>
          Cancel
        </Button>
      </Col>
      {!isAdding && type !== "Equity" ? (
        <Col>
          <Button variant="danger" onClick={api.delete}>
            Delete {type}
          </Button>
        </Col>
      ) : (
        ""
      )}
    </Row>
  );
};

export const Label = (props) => (
  <Form.Group>
    <Form.Label>{props.text}</Form.Label>
  </Form.Group>
);

const InputWithLabel = (props) => (
  <Form.Group>
    {getFormLabel(props.label)}
    <Form.Control
      as={props.as}
      disabled={props.disabled}
      max={props.max}
      min={props.min}
      onChange={props.onChange}
      required={props.required}
      rows={props.rows}
      step={props.step}
      type={props.type}
      value={props.value}
    />
  </Form.Group>
);

export const TextInputRequired = (props) => (
  <InputWithLabel
    {...props}
    onChange={(e) => props.setMethod(getValue(e))}
    required
    type="text"
  />
);

export const EmailInput = (props) => (
  <InputWithLabel
    {...props}
    onChange={(e) => props.setMethod(getValue(e))}
    type="email"
  />
);

export const DateInput = (props) => (
  <Form.Group>
    {getFormLabel(props.label)}
    <br />
    <DatePicker
      dateFormat="MMMM d, yyyy"
      onChange={(e) => props.setMethod(e)}
      required={props.required}
      selected={props.value}
      type="date"
    />
  </Form.Group>
);

export const DateInputRequired = (props) => <DateInput {...props} required />;

export const FloatInput = (props) => (
  <InputWithLabel
    {...props}
    onChange={(e) => props.setMethod(parseFloat(getValue(e)))}
    type="number"
    step="0.01"
  />
);

export const FloatInputRequired = (props) => <FloatInput {...props} required />;

export const SelectInput = (props) => (
  <Form.Group>
    {getFormLabel(props.label)}
    <Form.Control
      as="select"
      onChange={(e) => props.setMethod(getValue(e))}
      required={props.required}
      type="text"
      value={props.value}
    >
      <option key=""></option>
      {props.options}
    </Form.Control>
  </Form.Group>
);

export const SelectInputRequired = (props) => (
  <SelectInput {...props} required />
);

export const CheckboxInput = (props) => (
  <Form.Group>
    <Form.Check
      checked={props.checked}
      id={props.label}
      label={props.label}
      onChange={(e) => props.setMethod(e.target.checked)}
      type="checkbox"
    />
  </Form.Group>
);

export const TextAreaInput = (props) => (
  <InputWithLabel
    {...props}
    as="textarea"
    rows="3"
    onChange={(e) => props.setMethod(getValue(e))}
  />
);

export const IntInput = (props) => (
  <InputWithLabel
    {...props}
    onChange={(e) => props.setMethod(parseInt(getValue(e)))}
    type="number"
  />
);

export const PercentInputRequired = (props) => (
  <FloatInputRequired {...props} min="0" max="100" />
);

export const IntInputRequired = (props) => <IntInput {...props} required />;

export const DisabledInput = (props) => <InputWithLabel {...props} disabled />;

export const QuantityInput = (props) => <IntInputRequired {...props} min="1" />;
