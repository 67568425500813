import React from "react";
import styled from "styled-components";
import { Container, Col, Row } from "react-bootstrap";

const Title = styled.h1`
  color: #1b3465;
  font-size: 45px;
  text-align: center;
  font-weight: 700;
`;

const Underline = styled.div`
  background-color: #1b3465;
  height: 2px;
  width: 100%;
`;

const Center = styled.div`
  text-align: center;
`;

const SectionHeader = (props) => (
  <Container>
    <Row>
      <Col md={12}>
        <Title fontSize={props.fontSize}>{props.title}</Title>
        <Center>
          {props.subtext1 && <h3>{props.subtext1}</h3>}
          <br />
          {props.subtext2 && <h3>{props.subtext2}</h3>}
        </Center>
      </Col>
      <Underline />
    </Row>
  </Container>
);

export default SectionHeader;
