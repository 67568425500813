import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import SectionHeader from "../components/SectionHeader";

const Text = styled.p`
  font-size: 22px;
  font-weight: 500;
`;

const SecondaryHeader = styled.h2`
  color: #1b3465;
  font-weight: 700;
  margin: 35px 0;
  text-decoration: underline;
`;

const About = () => (
  <Container>
    <Row>
      <Col>
        <SectionHeader title={"About 3D Winnipeg"} />
        <br />
        <Text>
          3D Winnipeg is a Winnipeg based 3D printing and design company. Our
          goal is to provide high quality manufacturing and design solutions
          through the use of additive manufacturing.
          <br />
          <br />
          At 3D Winnipeg, we specialize in prototype design and additive
          manufacturing. Innovation is what we are about and we are here to work
          with you and provide our insight and experience to ensure a high
          quality product and bring new ideas and designs to life. Through the
          use of 3D printing we also provide small batch manufacturing and the
          possibility to manufacture prototypes to get a hands on feel of your
          idea before bringing it to market.
          <br />
          <br />
          Our vision is to bridge the gap between readily available 3D printed
          products and consumer.
        </Text>
      </Col>
    </Row>
  </Container>
);

export default About;
