import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Col, Row } from "react-bootstrap";
import { SaleContext } from "../../../context/SaleContext";
import { FieldsContext } from "../../../context/FieldsContext";
import getEmployees from "../../../getEmployees";
import {
  SelectInputRequired,
  SelectInput,
  FloatInputRequired,
  DateInputRequired,
  EditForm,
  QuantityInput,
  PercentInputRequired,
  DisabledInput,
} from "../../../inputs";
import { getProducts, getClients } from "../../../api";
import { createOptions } from "../../../createOptions";
import FormInit from "../../../FormInit";

const SaleForm = (props) => {
  FormInit(props.action, "Sale");

  const history = useHistory();

  const [activeSale, setActiveSale] = useContext(SaleContext);
  const [amount, setAmount] = useState(activeSale?.amount);
  const [clientID, setClientID] = useState(activeSale?.clientID);
  const [date, setDate] = useState(new Date(activeSale?.date || new Date()));
  const [productID, setProductID] = useState(activeSale?.productID);
  const [quantity, setQuantity] = useState(activeSale.quantity);
  const [profit, setProfit] = useState(activeSale.profit);
  const [secondaryUser, setSecondaryUser] = useState(activeSale?.secondaryUser);
  const [user, setUser] = useState(activeSale?.user);
  const [userPercentage, setUserPercentage] = useState(
    activeSale.userPercentage || 100
  );

  const [products, setProducts] = useState([]);
  const [clients, setClients] = useState([]);

  const getData = async () => {
    const products = getProducts();
    const clients = getClients();
    setProducts(await products);
    setClients(await clients);
  };

  useEffect(() => {
    getData();
  }, []);

  const [, setFields] = useContext(FieldsContext);
  useEffect(() => {
    const fields = {
      amount,
      clientID,
      date,
      productID,
      profit,
      quantity,
      secondaryUser,
      user,
      userPercentage,
    };
    setActiveSale(fields);
    setFields(fields);
  }, [
    amount,
    clientID,
    date,
    productID,
    secondaryUser,
    user,
    setActiveSale,
    setFields,
    quantity,
    profit,
    userPercentage,
  ]);

  const inputs = [
    <SelectInputRequired
      label="Product"
      options={createOptions(products, "name", "id")}
      setMethod={setProductID}
      value={productID}
    />,
    <Button onClick={() => history.push("/admin/products/add")}>
      Add New Product
    </Button>,
    <SelectInputRequired
      label="Client"
      options={createOptions(clients, "name", "id")}
      setMethod={setClientID}
      value={clientID}
    />,
    <Button onClick={() => history.push("/admin/clients/add")}>
      Add New Client
    </Button>,
    <DateInputRequired label="Date" setMethod={setDate} value={date} />,
    <QuantityInput label="Quantity" setMethod={setQuantity} value={quantity} />,
    <FloatInputRequired
      label="Total Price"
      setMethod={setAmount}
      value={amount}
    />,
    <FloatInputRequired label="Profit" setMethod={setProfit} value={profit} />,
    <Form.Group as={Row}>
      <Col>
        <SelectInputRequired
          label="Employee"
          options={getEmployees}
          setMethod={setUser}
          value={user}
        />
      </Col>
      <Col>
        <PercentInputRequired
          label="Percentage of Profit"
          setMethod={setUserPercentage}
          value={userPercentage}
        />
      </Col>
      <Col>
        <DisabledInput
          label="Personal Profit"
          value={((profit || 0) * 0.5 * (userPercentage || 0)) / 100}
        />
      </Col>
    </Form.Group>,
    <Form.Group as={Row}>
      <Col>
        <SelectInput
          label="Secondary Employee"
          options={getEmployees}
          setMethod={setSecondaryUser}
          value={secondaryUser}
        />
      </Col>
      <Col>
        <DisabledInput
          label="Percentage of Profit"
          value={100 - (userPercentage || 0)}
        />
      </Col>
      <Col>
        <DisabledInput
          label="Personal Profit"
          value={((profit || 0) * 0.5 * (100 - userPercentage || 0)) / 100}
        />
      </Col>
    </Form.Group>,
  ];

  return <EditForm inputs={inputs} />;
};

export default SaleForm;
