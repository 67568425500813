import React from "react";

export const createOptions = (data, displayField, valueField) =>
  data.map((e) => {
    const value = e[valueField];
    const display = e[displayField];
    return (
      <option key={value} value={value}>
        {display}
      </option>
    );
  });
