import React from "react";
import styled from "styled-components";
import { Button, Container, Row, Col, Image } from "react-bootstrap";
import SectionHeader from "../components/SectionHeader";
import homeBanner1 from "../assets/homeBanner1.jpg";
import design from "../assets/design.png";
import manufacturing from "../assets/manufacturing.png";
import consulting from "../assets/consulting.JPG";
import caspianConstruction from "../assets/caspianConstruction.png";
import rTech from "../assets/rTech.jpg";
import price from "../assets/price.svg";
import VerticalImageInfo from "../components/VerticalImageInfo";

const ImageText = styled.h1`
  color: white;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 30px;
  position: absolute;
  text-align: center;
  top: 40%;
  width: 100%;
  z-index: 2;
`;

const BannerWrapper = styled.div`
  background-color: black;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const BannerImage = styled(Image)`
  max-height: 700px;
  object-fit: cover;
  object-position: 50% 50%;
  opacity: 0.6;
  width: 100%;
`;

const Description = styled.p`
  font-size: 29px;
  margin-top: 40px;
  text-align: center;
`;

const LearnMoreButton = styled(Button)`
  background-color: #1b3465;
  border: none;
  border-radius: 15px;
  float: right;
  font-size: 20px;
  padding: 5px 30px;
`;

const PartnersHeader = styled.h2`
  font-size: 30px;
  font-weight: 600;
  margin-top: 40px;
`;

const PartnerImage = styled(Image)`
  float: left;
  margin-right: 15px;
  margin-top: 10px;
  max-height: 53px;
  max-width: 75px;
`;

const PartnerName = styled.p`
  font-size: 20px;
  margin-top: 18px;
`;

const Home = () => (
  <>
    <Container fluid>
      <br />
      <Row>
        <Col>
          <BannerWrapper>
            <ImageText>Bringing Designs to Life, One Layer at a Time</ImageText>
            <BannerImage fluid src={homeBanner1} alt="First slide" />
          </BannerWrapper>
        </Col>
      </Row>
    </Container>
    <br />
    <br />
    <Container>
      <Row>
        <Col>
          <SectionHeader title={"Let Our Experts Help You"} />
        </Col>
      </Row>
      <Row>
        <Description>
          At 3D Winnipeg, we specialize in 3D printing and prototype design. Innovation is what we are about and we are here to work
          with you and provide our insight and experience to ensure a high
          quality product. Through the
          use of 3D printing we provide small batch manufacturing and the
          possibility to manufacture a sample of all prototypes to get a hands
          on feel of your idea before bringing it to market.
        </Description>
      </Row>
      <Row>
        <Col>
          <br />
          <br />
          <a href="/about">
            <LearnMoreButton>Learn More</LearnMoreButton>
          </a>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Col>
          <SectionHeader title={"What We Offer"} />
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <VerticalImageInfo
          src={design}
          primaryHeader={"Design"}
          secondaryHeader={"Bring Your Idea to Life"}
          description={
            "3D Winnipeg helps clients with their ideas by offering our 3D Modelling skills combined with our expertise in additive manufacturing to allow you to explore design options and get a hands on feel of your idea."
          }
        />
        <VerticalImageInfo
          src={manufacturing}
          primaryHeader={"Manufacturing"}
          secondaryHeader={"Construct Your Design"}
          description={
            "Through the use of additive manufacturing, we provide companies a cost effective and efficient way to manufacture products that are more complex, lighter and with shorter lead times than traditional manufacturing methods."
          }
        />
        <VerticalImageInfo
          src={consulting}
          primaryHeader={"Consulting"}
          secondaryHeader={"Let Our Experts Help"}
          description={
            "We have a team of engineers with a vast amount of experience to help you discover what additive manufacturing can do for you. Get in touch with our team to assist you with your next project."
          }
        />
      </Row>
      <Row>
        <Col>
          <a href="/services">
            <LearnMoreButton>Learn More</LearnMoreButton>
          </a>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Col>
          <SectionHeader title={"How to Apply AM to Your Business"} />
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <VerticalImageInfo
          src={design}
        />
        <VerticalImageInfo
          src={manufacturing}
        />
        <VerticalImageInfo
          src={consulting}
        />
      </Row>
      <Row>
        <VerticalImageInfo
          src={design}
        />
        <VerticalImageInfo
          src={manufacturing}
        />
        <VerticalImageInfo
          src={consulting}
        />
      </Row>

      <Row>
        <Col>
          <PartnersHeader>Trusted Clients</PartnersHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <a
            href="https://www.caspianconstruction.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PartnerImage src={caspianConstruction} />
            <PartnerName>Caspian Construction</PartnerName>
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <a
            href="http://new.r-techind.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PartnerImage src={rTech} />
            <PartnerName>R-Tech Industries Ltd.</PartnerName>
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <a href="https://www.priceindustries.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PartnerImage src={price} />
            <PartnerName>Price Industries</PartnerName>
          </a>
        </Col>
      </Row>
    </Container>
    <br />
    <br />
  </>
);

export default Home;
