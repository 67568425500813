import React from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";

const TablePage = (props) => (
  <Container>
    <TablePiece {...props} />
  </Container>
);

export const TablePiece = (props) => (
  <>
    <Row>
      {props.buttons.map((button, i) => (
        <Col key={i}>
          <Button onClick={button.onClick}>{button.text}</Button>
        </Col>
      ))}
    </Row>
    <Row>
      <Col>
        <Table striped bordered hover>
          <thead>
            <tr>
              {props.tableHeaders.map((header, i) => (
                <th key={i}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.tableRows.map((row) => (
              <tr key={row.id} onClick={() => props.onRowClick(row)}>
                {props.getCells(row).map((cell, i) => (
                  <td key={i}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  </>
);

export default TablePage;
