import React from "react";
import { Form } from "react-bootstrap";

const FormGroup = (props) => (
  <Form.Group className="mb-3">
    {props.children}
  </Form.Group>
);

export default FormGroup;
