import React, { Fragment } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import SectionHeader from "../components/SectionHeader";

const AdvantagesWrapper = styled.h4`
  color: #00d617;
`;

const DisadvantagesWrapper = styled.h4`
  color: #fa3119;
`;

const Text = styled.p`
  font-size: 22px;
`;

const StyledLI = styled.li`
  font-size: 22px;
`;

const Material = (props) => (
  <Fragment>
    <Row>
      <Col>
        <h1>{props.title}</h1>
        <Text>{props.description}</Text>
        <br />
        <Text>
          <b>
            <u>Common Uses</u>
          </b>
          : {props.commonUses}
        </Text>
      </Col>
    </Row>
    <br />
    <Row>
      <Col xs={6}>
        <AdvantagesWrapper>Advantages</AdvantagesWrapper>
        <ul>
          {props.advantages.map((item) => (
            <StyledLI>{item}</StyledLI>
          ))}
        </ul>
      </Col>
      <Col xs={6}>
        <DisadvantagesWrapper>Disadvantages</DisadvantagesWrapper>
        <ul>
          {props.disadvantages.map((item) => (
            <StyledLI>{item}</StyledLI>
          ))}
        </ul>
      </Col>
    </Row>
    <br />
  </Fragment>
);

const Materials = () => (
  <Container>
    <Row>
      <Col>
        <SectionHeader title={"Materials"} />
      </Col>
    </Row>
    <Material
      title={"PLA"}
      description={
        "The most commonly used material for 3D printing. PLA is biodegradable and easy to print with and great for printing a various selection of objects."
      }
      commonUses={
        "Printing toys, prototypes, figures, architecture models and more."
      }
      advantages={[
        "Affordable",
        "High level of detail",
        "Wide colour selection",
        "Easy to print with",
      ]}
      disadvantages={[
        "Brittle",
        "Usage temperature below 60℃",
        "Not suitable for outdoor use",
      ]}
    />
    <Material
      title={"PETG"}
      description={
        "Another commonly used 3D printing material. PETG is stronger than PLA which makes this a great material for printing mechanical components. PETG has higher temperature resistance than PLA (approximately 75℃) and is less brittle."
      }
      commonUses={"Mechanical components, watertight prints (vases, pots etc.)"}
      advantages={[
        "Affordable",
        "Stronger than PLA",
        "Good surface finish",
        "Does not absorb water",
      ]}
      disadvantages={[
        "Possible stringing while printing",
        "Not suitable for smaller models",
      ]}
    />
    <Material
      title={"TPU/FLEX"}
      description={
        "TPU is a very strong and elastic material that has more specified applications, primarily preventing damage to components while acting as a casing. TPU has a melting temperature of around 190℃ - 220℃."
      }
      commonUses={"Flexible models, cases and wheels."}
      advantages={["Flexible", "Abrasion resistant"]}
      disadvantages={[
        "Slightly higher price",
        "More challenges while printing",
      ]}
    />
    <Material
      title={"Nylon"}
      description={
        "Nylon is a very strong, durable and versatile material very suited for mechanical components. Recommended usage temperature below 80℃."
      }
      commonUses={
        "Replacement car parts, mechanical components, gearboxes etc."
      }
      advantages={[
        "Good mechanical properties",
        "Higher usage temperature",
        "Chemical resistance",
      ]}
      disadvantages={["Higher price", "More difficult to print"]}
    />
  </Container>
);

export default Materials;
