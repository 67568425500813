import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import SectionHeader from "../components/SectionHeader";

const Text = styled.p`
  font-size: 22px;
  font-weight: 500;
`;

const SecondaryHeader = styled.h2`
  color: #1b3465;
  font-weight: 700;
  margin: 35px 0;
  text-decoration: underline;
`;

const About = () => (
  <Container>
    <Row>
      <Col>
        <SectionHeader title={"Additive Manufacturing Applications"} />
        <br />
        <SecondaryHeader>
	  Batch Manufacturing
	</SecondaryHeader>
	<Text>
	  3D printing is ideal for small to medium scale manufacturing as it avoids the high setup costs of large scale production runs
	</Text>
        <SecondaryHeader>
	   End use application
	</SecondaryHeader>
	<Text>
	  Certain parts or products make great candidates to manufacture using 3D printing. The complexity of certain parts allows 3D printing to significantly reduce costs of manufacturing compared to traditional methods and allow a competitive edge in your business.
	</Text>
        <SecondaryHeader>
	  New Design space
	</SecondaryHeader>
	<Text>
	  With Additive manufacturing’s ability to create complex components, new designs are possible that allow for weight reduction, consolidation of assembly and the creation of organic structures.
	</Text>
        <SecondaryHeader>
	  Rapid prototyping
	</SecondaryHeader>
	<Text>
	  Rapid prototyping can help with visualization, design and development of the manufacturing process ahead of production – all while keeping cost and turnaround times low.
	</Text>
        <SecondaryHeader>
	  Digital Factory
	</SecondaryHeader>
	<Text>
	  Additive manufacturing allows you to consolidate your inventory into digital files and 3D print required parts on demand. This reduces cost of inventory and eliminates major supply chain issues.
	</Text>
        <SecondaryHeader>
	  Construction 
	</SecondaryHeader>
	<Text>
	  Additive manufacturing can be used to create scale models of your construction/renovation project for ease of communication with all parties involved, reduced design iterations and a better overall visual of the results of your project.
	</Text>
      </Col>
    </Row>
  </Container>
);

export default About;
