import React, { useState } from "react";

const ExpenseContext = React.createContext([{}, () => { }]);

const ExpenseProvider = (props) => {
  const [state, setState] = useState({});
  return (
    <ExpenseContext.Provider value={[state, setState]}>
      {props.children}
    </ExpenseContext.Provider>
  );
};

export { ExpenseContext, ExpenseProvider };
