import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import OrderForm from "./OrderForm";
import GetOrders from "./GetOrders";

const Orders = () => {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/add`}>
          <OrderForm action="add" />
        </Route>
        <Route exact path={`${match.path}/get`} component={GetOrders} />
        <Route exact path={`${match.path}/update`}>
          <OrderForm action="update" />
        </Route>
      </Switch>
    </>
  );
};

export default Orders;
