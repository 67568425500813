import React from "react";
import styled from "styled-components";
import { Row, Col, Image as BootstrapImage } from "react-bootstrap";

const PrimaryHeader = styled.h2`
  color: #1b3465;
  font-weight: 700;
  margin: 35px 0;
  text-decoration: underline;
`;

const SecondaryHeader = styled.h3`
  font-weight: 600;
`;

const Image = styled(BootstrapImage)`
  width: 100%;
`;

const HorizontalImageInfo = (props) => (
  <>
    <Row>
      <Col>
        <PrimaryHeader>{props.primaryHeader}</PrimaryHeader>
      </Col>
    </Row>
    <Row>
      <Col>
        <SecondaryHeader>{props.secondaryHeader}</SecondaryHeader>
      </Col>
    </Row>
    <Row>
      <Col md={7}>{props.description}</Col>
      <Col md={5}>
        <Image src={props.src} />
      </Col>
    </Row>
  </>
);

export default HorizontalImageInfo;
